import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MapperTypes } from '@enums';
import { ApiService } from '@services/api.service';
import { ConfigService } from '@services/config.service';
import { EntityService } from '@services/entity.service';
import { LockingService } from '@services/locking.service';
import { cloneDeep } from 'lodash-es';
import { ToastrService } from 'ngx-toastr';
import { Subject, lastValueFrom, throwError } from 'rxjs';
import { generalDatafieldField, mainFields, productDatafieldField } from './form-types/configs/form.configs';

@Component({
  selector: 'app-contract-form',
  templateUrl: './contract-form.component.html',
  styleUrls: ['./contract-form.component.scss', '../inner-form/inner-form.component.scss'],
  standalone: false,
})
export class ContractFormComponent implements OnInit, OnDestroy, OnChanges {
  @Input() data: any = [];

  @Output() variablesDataChanged = new EventEmitter<any>();

  private alive = new Subject<void>();

  public title: string = '';
  public description: string = '';
  public contractForms: FormGroup[] = [];
  public mapperForms;

  public contractModel: any = [];

  public isLoading: boolean = false;
  public newlyAdded: boolean = false;

  public lockEditMode: boolean = false;
  public contractSchema;

  public mainFields = [];

  constructor(
    private apiService: ApiService,
    private configService: ConfigService,
    private entityService: EntityService,
    private lockingService: LockingService,
    private toastService: ToastrService
  ) {}

  async ngOnInit(): Promise<void> {
    // console.log(this.data);
    this.title = this.configService.getEntityFormTitle('FormVariableContentResource');
    this.description = this.configService.getEntityDescription('FormVariableContentResource');

    // needed?
    this.contractSchema = this.configService.currentOpenApiConfig.components.schemas['FormVariableContentResource'];

    this.dataFieldsHandler();
  }

  ngOnDestroy(): void {
    this.alive.next();
    this.alive.unsubscribe();
  }

  ngOnChanges(change: SimpleChanges) {
    if (!!change?.data?.currentValue) {
      // console.log(`MODEL CHANGE!!! `, change.data.currentValue);
      this.contractForms = [];
      this.contractModel = [];
      this.mainFields = [];

      const cmp = (a: any, b: any) => {
        return a > b ? +1 : a < b ? -1 : 0;
      };

      change.data.currentValue.sort((a, b) => {
        return cmp(a.id, b.id);
      });

      change.data.currentValue.forEach((element, formIndex) => {
        this.contractForms[formIndex] = new FormGroup({});
        this.contractModel[formIndex] = this.valueMapper(element);
        this.mainFields[formIndex] = cloneDeep(mainFields);
      });

      this.variablesDataChanged.emit(this.contractModel);
    } else if (change?.data?.currentValue === undefined) {
      // this happens when we add a new contract
      this.contractForms = [];
      this.contractModel = [];
      this.variablesDataChanged.emit(this.contractModel);
    }
  }

  public onAddVariable(): void {
    this.newlyAdded = true;
    this.mainFields.push(cloneDeep(mainFields));
    this.contractForms.push(new FormGroup({}));
    this.contractModel.push({});
  }

  public onDeleteVariable(index: number): void {
    this.contractForms = this.contractForms.filter((element, i) => i !== index);
    this.contractModel = this.contractModel.filter((element, i) => i !== index);
    this.mainFields = this.mainFields.filter((element, i) => i !== index);
    this.variablesDataChanged.emit(this.contractModel);
    this.toastService.warning('Element gelöscht. Eintrag speichern, um Änderung zu übernehmen');
  }

  public onResetVariable(index: number): void {}

  public onCopyVariable(index: number): void {
    const copiedModel = Object.assign(
      {},
      this.contractModel.find((element, i) => i === index)
    );

    if (copiedModel.id) {
      delete copiedModel.id;
    }

    this.contractForms.push(new FormGroup({}));
    this.contractModel.push(copiedModel);
    this.mainFields.push(cloneDeep(mainFields));

    this.variablesDataChanged.emit(this.contractModel);
    this.toastService.warning(
      `Variable "${copiedModel.variableId}" dupliziert. Eintrag speichern, um Änderung zu übernehmen`
    );
  }

  public handleMapperTypeSelection(event: any, formIndex: number): void {
    // event?.mappers.forEach((mapper, mapIndex) => {
    //   const modelMapper = this.contractModel[formIndex]?.mappers?.[mapIndex] || null;
    //   const oldType = modelMapper?.value?.type || null;
    //   const newType = mapper?.value?.type || null;
    //   if (newType !== oldType) {
    //     // console.log(`DIFFERENT MAPPER TYPE`);
    //     // console.log(newType);
    //   }
    // });

    this.contractModel[formIndex] = this.valueMapper(event, false);
    this.variablesDataChanged.emit(this.contractModel);
  }

  public contractVariableTitle(formIndex: number): string {
    if (!this.contractModel[formIndex]?.variableId) {
      return 'Neue Vertragsvariable';
    } else if (this.contractForms.length === 1) {
      return 'Vertragsvariable';
    } else {
      return `Vertragsvariable ${formIndex + 1} von ${this.contractForms.length}`;
    }
  }

  private async dataFieldsHandler() {
    try {
      const dataFields = await lastValueFrom(this.apiService.get('data-field-groups'));
      const generalDataFields = dataFields
        .filter(x => x.type === 'GENERAL')
        .flatMap(x => x.dataFields)
        .map(x => {
          return {
            value: x.contentId,
            dataFieldContentId: x.contentId,
            label: `[${x.id.substring(0, 8)}] ${x.adminName}`,
          };
        });
      const productDataFields = dataFields
        .filter(x => x.type !== 'GENERAL')
        .flatMap(x => x.dataFields)
        .map(x => {
          return {
            value: x.contentId,
            dataFieldContentId: x.contentId,
            label: `[${x.id.substring(0, 8)}] ${x.adminName}`,
          };
        });
      generalDatafieldField.setData(generalDataFields);
      productDatafieldField.setData(productDataFields);
    } catch (e) {
      throwError(e);
    }
  }

  private valueMapper(data, setValue: boolean = true): any {
    const setValueItem = value => {
      if (value.type === MapperTypes.generalDataField || value.type === MapperTypes.productDataField) {
        if (setValue) {
          value.value = value.dataFieldContentId;
        } else {
          value.dataFieldContentId = value.value;
        }
      }
      return value;
    };

    data?.mappers?.forEach(mapperItem => {
      setValueItem(mapperItem.value);

      mapperItem?.conditions?.forEach(conditionItem => {
        setValueItem(conditionItem.keyValue);
        setValueItem(conditionItem.matchingValue);
      });
    });

    return data;
  }

  get emptyMessage(): string {
    return `Noch keine ${this.title} vorhanden`;
  }
}
